import Cookies from 'js-cookie';

export function init() {
	'use strict';

	const $cookie = $('.js-cookie');
	const $cookieAccept = $('.js-cookie-accept');

	if ($cookie.length && $cookieAccept.length) {
		const isChecked = Cookies.get('policy-accepted');

		if (!isChecked) {
			$cookie.addClass('is-active');
		}

		$cookieAccept.on('click', function() {
			Cookies.set('policy-accepted', true, { expires: 365 });
			$cookie.removeClass('is-active');
		});
	}

}