export function init() {
	'use strict';

	const $loader = $('.js-loader');
	const $home = $('.js-home');

	if ($loader.length) {
		$loader.addClass('is-active');

		setTimeout(function () {
			$loader.addClass('is-filled');
		}, 1250);

		setTimeout(function () {
			$loader.addClass('is-loaded');
			setTimeout(() => {
				$home.addClass('site-content--home-ready');
			}, 1000);
		}, 5000);
	}

}