export function init() {
	'use strict';

	const $body = $('.js-site-body');
	const $links = $('.js-inner-link');
	const $loader = $('.js-common-loader');
	if ($body.hasClass('md-desktop') && $links.length && $loader.length) {

		const $bodyBg = $('.js-body-bg');
		setTimeout(() => {
		    $loader.removeClass('is-active');
		    if ($bodyBg.length) {
		    	setTimeout(() => {
		    		$bodyBg.addClass('is-active');
		    	}, 600);
		    }
		}, 400);

	    $links.on('click', function(e) {
	    	e.preventDefault();

	    	$loader.addClass('is-active');

	    	const $link = $(this);

	    	setTimeout(() => {
	    		window.location.href = $link.attr('href');
	    	}, 800);
	    });
	}

	window.onunload = function(){};
}