require('slick');

export function horizontal($slider, options) {
    'use strict';

    let defaults = {
        accessibility: false,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        dots: false,
        fade: false,
        infinite: false,
        pauseOnHover: true,
        slidesToShow: 3,
        speed: 500,
        prevArrow: '<span class="slick-prev"><span class="icon icon-arrow-thin-left"></span></span>',
        nextArrow: '<span class="slick-next"><span class="icon icon-arrow-thin-right"></span></span>'
    };

    let settings = $.extend({}, defaults, options);

    if ($slider.length) {

        $slider.each(function() {

            let $thisSlider = $(this),
                thisSettings = settings;

            $thisSlider.on('init beforeChange afterChange', function () {

                $(window).trigger('scroll');

            });

            // for checking direction of sliding
            // $thisSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {

            //     let decl = currentSlide - nextSlide;

            //     if (decl === 1 || decl === (slick.slideCount - 1) * (-1)) {
            //         console.log('is prev direction');
            //     } else {
            //         console.log('is next direction');
            //     }

            // });

            if (!$thisSlider.hasClass('slick-initialized')) {
                $thisSlider.slick(thisSettings);
            }

        });

    }

    return $slider;

}

export function gallerySlider() {
    'use strict';

    const $gallerySliders = $('.js-gallery-slider');

    if ($gallerySliders.length) {

        $gallerySliders.each(function() {

            const $gallerySlider = $(this);
            const $galleryIndicators = $gallerySlider.parent().prev('.js-gallery-indicators');
            const $galleryMirror = $gallerySlider.next('.js-mirror-hld').find('.js-mirror-slider');
            const gallerySize = $gallerySlider.find('.js-slide').length - 1;
            const $singleText = $gallerySlider.prev('.js-single-gallery-text');

            const $textSlider = $gallerySlider.prev('.js-gallery-titles');
            const $contentSlider = $gallerySlider.parent().next('.js-contents-slider');

            if ($galleryIndicators.length) {
                const $indicatorsText = $galleryIndicators.find('.js-indicator-current');

                $gallerySlider.on('beforeChange', function(e, slick, cur, next) {

                    // CHECK FOR INDICATOR
                    const nextNum = next + 1;
                    if (cur !== next) {
                        let dir;
                        if (Math.abs(next - cur) === 1) {
                            dir = (next - cur > 0) ? 'right' : 'left';
                        }
                        else {
                            dir = (next - cur > 0) ? 'left' : 'right';
                        }

                        const nextVal = ('0' + nextNum).slice(-2);
                        $indicatorsText.attr('data-next', nextVal);
                        $indicatorsText.text(nextVal);
                        $indicatorsText.addClass('indicators__current--'+dir);
                        setTimeout(function() {
                            $indicatorsText.attr('data-current', nextVal);
                            $indicatorsText.removeClass('indicators__current--'+dir);
                        }, 500);
                    }

                    // CHECK ARROWS TEXT
                    const $slickArrowsText = $gallerySlider.find('.js-slick-text');
                    if ($slickArrowsText.length) {
                        $slickArrowsText.addClass('is-hidden');
                    }

                });
            }

            $gallerySlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                if (currentSlide > nextSlide && nextSlide === 0 && currentSlide === gallerySize) {
                    $galleryMirror.slick('slickGoTo', -1);
                } else if (currentSlide < nextSlide && currentSlide === 0 && nextSlide === gallerySize) {
                    $galleryMirror.slick('slickGoTo', gallerySize + 1);
                } else {
                    $galleryMirror.slick('slickGoTo', gallerySize - nextSlide);
                }

                if ($singleText.length) {
                    if (nextSlide === 0) {
                        $singleText.removeClass('is-hidden');
                    } else {
                        $singleText.addClass('is-hidden');
                    }
                } else if ($textSlider.length) {
                    $textSlider.slick('slickGoTo', nextSlide);
                }

                if ($contentSlider.length) {
                    $contentSlider.slick('slickGoTo', nextSlide);
                }

            });

            $gallerySlider.on('init afterChange', function(e, slick, cur) {
                const currentNum = cur ? cur : 0;
                const $currentSlide = $(slick.$slides[currentNum]);
                // CHECK NAMES
                const $prevSlide = $currentSlide.prev();
                const $nextSlide = $currentSlide.next();
                const prevTitle = $prevSlide.attr('data-title');
                const nextTitle = $nextSlide.attr('data-title');

                if (prevTitle && nextTitle) {
                    const $prevArrow = $(slick.$prevArrow);
                    const $nextArrow = $(slick.$nextArrow);
                    if ($prevArrow.length && $nextArrow.length) {
                        const $prevText = $prevArrow.find('.js-slick-text');
                        const $nextText = $nextArrow.find('.js-slick-text');

                        $prevText.text(prevTitle);
                        $nextText.text(nextTitle);

                        $prevText.removeClass('is-hidden');
                        $nextText.removeClass('is-hidden');
                    }
                }

            });

            $gallerySlider.on('init beforeChange afterChange', function () {
                $(window).trigger('scroll');
            });

            if (!$gallerySlider.hasClass('slick-initialized')) {
                $gallerySlider.slick({
                    accessibility: false,
                    autoplay: false,
                    arrows: true,
                    dots: false,
                    fade: false,
                    infinite: true,
                    slidesToShow: 1,
                    vertical: true,
                    speed: 1000,
                    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
                    prevArrow: '<span class="slick-prev"><span class="icon icon-arrow-left"></span><span class="slick-arrow__text js-slick-text"></span></span>',
                    nextArrow: '<span class="slick-next"><span class="icon icon-arrow-right"></span><span class="slick-arrow__text js-slick-text"></span></span>'
                });

                $galleryMirror.slick({
                    vertical: true,
                    speed: 950,
                    slidesToShow: 1,
                    infinite: true,
                    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
                    initialSlide: gallerySize,
                    arrows: false
                });

                if ($textSlider.length) {
                    $textSlider.slick({
                        vertical: true,
                        speed: 1000,
                        slidesToShow: 1,
                        infinite: true,
                        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
                        arrows: false,
                        swipe: false
                    });
                }

                if ($contentSlider.length) {
                    $contentSlider.slick({
                        vertical: true,
                        speed: 1000,
                        slidesToShow: 1,
                        infinite: true,
                        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
                        arrows: false,
                        swipe: false
                    });
                }

            }

        });
    }

    const $galleryMobile = $('.js-gallery-slider-mobile');

    if ($galleryMobile.length) {

        $galleryMobile.each(function() {

            const $this = $(this);

            $this.slick({
                accessibility: false,
                autoplay: false,
                arrows: true,
                dots: false,
                fade: false,
                infinite: true,
                slidesToShow: 1,
                speed: 500,
                cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
                prevArrow: '<span class="slick-prev"><span class="icon icon-arrow-left"></span></span>',
                nextArrow: '<span class="slick-next"><span class="icon icon-arrow-right"></span></span>'
            });

        });

    }


}