require('headjs');
import * as slicks from './slicks';
/* eslint-disable */
// jshint ignore: start
export function init() {
	'use strict';

	const $slider = $('.js-products');

	const $dotContainer = $('.js-dots');
	const $dotHolder = $('.js-dots-hld');
	const $dots = $('.js-dot-item');
    const $showContents = $('.js-products-hld');

    if ($slider.length && $dots.length) {

        const $slides = $slider.find('.js-slide');
        let $thisSlide = $slides.eq(0);

        head.load('/js/sketchfab.js', function() {

            let $arrows;

            $slider.on('init', function() {
                $arrows = $('.slick-arrow');
                watchDots();
            });

            // DOTS
            let dotContainerWidth = $dotContainer.outerWidth() / 2;

            const goToDot = function(index) {
                const $dot = $dots.eq(index);
                $dots.not($dot).removeClass('is-active');
                $dot.addClass('is-active');
                const dotPosition = dotContainerWidth + $dotHolder.offset().left - $dot.offset().left;
                $dotHolder.css({
                    transform: 'translateX('+dotPosition+'px)'
                });
            };
            
            setTimeout(() => {
                $dotHolder.addClass('is-visible');
            }, 500);

            const watchDots = function() {
                if ($(window).outerWidth() < 1024) {
                    const $content = $thisSlide.find('.products__content');
                    const addition = $(window).outerWidth() < 768 ? 14 : 30;
                    let distance = $content.offset().top - $('.site-body__inner').offset().top + $content.outerHeight() + addition;
                    if (distance < 304) distance = 304;
                    $arrows.css({
                        '-webkit-transform' : 'translateY(' + distance + 'px)',
                        '-moz-transform'    : 'translateY(' + distance + 'px)',
                        '-ms-transform'     : 'translateY(' + distance + 'px)',
                        '-o-transform'      : 'translateY(' + distance + 'px)',
                        'transform'         : 'translateY(' + distance + 'px)'
                    });
                }
            };

            $(window).on('resize', function() {
                dotContainerWidth = $dotContainer.outerWidth() / 2;
            });

            // SLIDER

            $slider.on('beforeChange', function (e, slick, _, next) {
                const $currentSlide = $slides.eq(next);
                $thisSlide = $currentSlide;
                watchDots();

                if (!$currentSlide.hasClass('js-canvas-loaded')) {
                    const $waiter = $currentSlide.find('.js-waiter').first();

                    const $canvas = $currentSlide.find('.js-products-canvas');
                    const canvas = $canvas[0];
                    const sketchId = $canvas.attr('data-id');

                    const client = new Sketchfab( canvas );

                    /* eslint-disable */
                    client.init( sketchId, {
                        ui_controls: 0,
                        ui_annotations: 0,
                        annotations_visible: 0,
                        ui_stop: 0,
                        ui_animations: 0,
                        ui_fullscreen: 0,
                        ui_general_controls: 0,
                        ui_help: 0,
                        ui_hint: 0,
                        ui_infos: 0,
                        ui_inspector: 0,
                        ui_settings: 0,
                        ui_vr: 0,
                        transparent: 1,
                        autospin: 0.5,
                        success: function onSuccess( api ){
                            api.start();
                            api.addEventListener('viewerready', function() {
                                canvas.classList.add('products__frame--ready');
                                canvas.classList.add('is-visible');
                                if ($(window).outerWidth() < 1025) {
                                    if ($waiter.length) {
                                        setTimeout(() => {
                                            $waiter.addClass('is-swiped');
                                        }, 2000);
                                    }
                                }
                            });

                            if ($(window).outerWidth() > 1024) {
                                api.addEventListener('nodeMouseEnter', function() {
                                    if ($waiter.length) {
                                        setTimeout(() => {
                                            $waiter.addClass('is-swiped');
                                        }, 2000);
                                    }
                                });
                            }
                        },
                        error: function onError() {
                            console.log( 'Viewer error' );
                        }
                    } );

                    $currentSlide.addClass('js-canvas-loaded');
                }

                goToDot(next);
            });

            slicks.horizontal($slider, {
                slide: '.js-slide',
                speed: 750,
                autoplay: false,
                slidesToShow: 1,
                infinite: false,
                arrows: true,
                swipe: false,
                prevArrow: '<span class="slick-prev"><span class="icon icon-arrow-left"></span></span>',
                nextArrow: '<span class="slick-next"><span class="icon icon-arrow-right"></span></span>'
            });

            $dots.on('click', function() {
                const $dot = $(this);
                if (!$dot.hasClass('is-active')) {
                    $slider.slick('slickGoTo', $dot.index());
                }
            });

            $dots.first().trigger('click');

            if ($showContents.length) {

                const anotherWatch = function(height, isOpen) {
                    const $content = $thisSlide.find('.products__content');
                    const addition = isOpen ? $content.outerHeight() - $('.site-body__inner').offset().top + height - 2 : $content.outerHeight() - $('.site-body__inner').offset().top + 14 - height;
                    let distance = $content.offset().top + addition;
                    if (distance < 304) distance = 304;
                    $arrows.css({
                        '-webkit-transform' : 'translateY(' + distance + 'px)',
                        '-moz-transform'    : 'translateY(' + distance + 'px)',
                        '-ms-transform'     : 'translateY(' + distance + 'px)',
                        '-o-transform'      : 'translateY(' + distance + 'px)',
                        'transform'         : 'translateY(' + distance + 'px)'
                    });
                };

                $showContents.each(function() {
                    const $showContent = $(this);
                    const showInner = $showContent.find('.js-products-inner').outerHeight();
                    const $showButton = $showContent.next('.js-products-show');
                    const $hideContent = $showContent.prev('.js-products-title').find('.js-products-hide');

                    $showButton.on('click', function() {
                        anotherWatch(showInner, true);
                        $showContent.animate({'height': showInner+'px'}, 300, 'linear');
                        $showButton.addClass('is-hidden');
                        $hideContent.removeClass('is-hidden');
                    });            

                    $hideContent.on('click', function() {
                        anotherWatch(showInner, false);
                        $showContent.animate({'height': '0px'}, 300, 'linear');
                        $showButton.removeClass('is-hidden');
                        $hideContent.addClass('is-hidden');
                    });
                });
            }

        });

    }
}