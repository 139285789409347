require('headjs');
require('jquery');
require('jquerymigrate');

import * as classListPolyFill from './helpers/classListPolyFill';
import * as maps from './functions/maps';
import * as slicks from './functions/slicks';
import * as panorama from './functions/panorama';
import * as repairs from './functions/repairs';
import * as homeProducts from './functions/homeProducts';
import * as products from './functions/products';
import * as loader from './functions/loader';
import * as mobileNav from './functions/mobileNav';
import * as commonLoader from './functions/commonLoader';
import * as cookie from './functions/cookie';

// Starter packs
classListPolyFill.init();

if(navigator.userAgent.match('CriOS')) {
    document.querySelector('html').classList.add('ios');
}


$(function() {
    'use strict';

    cookie.init();
    
    mobileNav.init();

    commonLoader.init();

    homeProducts.init();

    loader.init();

    slicks.horizontal($('.js-slider'), {
        slide: '.js-slide',
        speed: 750,
        autoPlaySpeed: 2000,
        slidesToShow: 1,
        infinite: true,
        arrows: false
        // responsive: [
        //     {
        //         breakpoint: 1200,
        //         settings: {
        //             slidesToShow: 1
        //         }
        //     },
        //     {
        //         breakpoint: 768,
        //         settings: {
        //             slidesToShow: 1
        //         }
        //     }
        // ]
    });

    repairs.init();

    products.init();

    panorama.init();

    const $countries = $('.js-map-fill');
    const $body = $('.js-site-body');

    if ($countries.length) {
        const delay = $body.hasClass('md-desktop') ? 2100 : 0;
        setTimeout(() => {
            $countries.each(function(index) {
                setTimeout(() => {
                    $(this).addClass('is-active');
                }, index * 70);
                setTimeout(() => {
                    $('.js-map-sygnet').addClass('is-active');
                }, 980);
            });
        }, delay);
    }

    // REMEMBER TO ADD MARKER WITH LABEL AND INFOBOX.MIN.JS
    if ($('#map-canvas').length) {
    	const locale = 'pl';

        // head.load('//maps.googleapis.com/maps/api/js?key=AIzaSyAfNDQce7HG11AruIXFm-rWg_ewUWGBr9o&language=' + locale, function () {
        head.load('//maps.googleapis.com/maps/api/js?key=AIzaSyCYDelDqH6-8kUU7xkWMXK1XzyMuU7hKgk&language=' + locale, function () {
            	$('body').addClass('js-google-map-api-loaded');
            	maps.init();
            }
        );

    }

});