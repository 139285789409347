export function init() {
	'use strict';

	const $mobileNav = $('.js-mobile-nav');
	const $mobileTrigger = $('.js-mobile-trigger');
	const $body = $('.js-site-body');

	if ($mobileNav.length && $mobileTrigger.length) {
		$mobileTrigger.on('click', function() {
			$(this).toggleClass('sidebar__menu--open');
			$mobileNav.toggleClass('m-nav--open');
			$body.toggleClass('is-overflowing');
		});

	}

}