import * as slicks from './slicks';

export function init() {
	'use strict';

	const $nav = $('.js-products-nav');
	const $list = $('.js-products-list');

	const $slider = $('.js-products-slider');
	const $items = $('.js-products-item');

	if ($nav.length && $list.length && $items.length) {

		// MOUSE MOVE
		let navHeight = 0;
		let listHeight = 0;

		const calculateSize = function() {
			navHeight = $nav.outerHeight();
			listHeight = $list.outerHeight() - $items.first().outerHeight();
		};

		calculateSize();

		$(window).on('resize', function() {
			calculateSize();
		});

		$(window).on('mousemove', function(e) {
			const Y = -e.clientY/navHeight * listHeight;
			$list.css({
				'transform' : 'translateY('+Y+'px)'
			});
		});

		// SLICK

		slicks.horizontal($slider, {
		    slide: '.js-slide',
		    autoplay: false,
		    speed: 300,
		    slidesToShow: 1,
		    infinite: false,
		    arrows: false,
		    fade: true,
		    responsive: [
		    	{
		    		breakpoint: 1200,
		    		settings: {
		    			autoplay: true,
		    			autoplaySpeed: 5000,
		    			speed: 2000,
		    		}
		    	}
		    ]
		});

		$items.on('mouseenter mousemove', function() {
			const $item = $(this);

			$items.not($item).removeClass('is-active');
			$item.addClass('is-active');

			const itemIndex = $item.index();
			$slider.slick('slickGoTo', itemIndex);
		});

		if ($(window).outerWidth() < 1200) {
			$slider.slick('slickGoTo', 1);
		}

	}

}
