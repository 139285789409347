export function init() {
	'use strict';

	const item = document.querySelector('#panorama');
	const $item = $(item);
	const container = document.querySelector('#panoramaHld');
	const $swipeLeft = $('.js-swipe-left');
	const $swipeRight = $('.js-swipe-right');

	if (item && container && $swipeLeft.length && $swipeRight.length) {

		let containerWidth = 0;
		let itemWidth = 0;

		let scrollFlag = true;

		const calculateWidths = function() {
			containerWidth = container.offsetWidth;
			itemWidth = item.offsetWidth;
		};

		calculateWidths();

		setTimeout(function () {
			calculateWidths();
		}, 500);

		let active = false;
		let currentX;
		let initialX;
		let xOffset = $(window).outerWidth() > 1200 ? -500 : -350;

		const dragStart = function(e) {
		  if (e.type === 'touchstart') {
		    initialX = e.touches[0].clientX - xOffset;
		  } else {
		    initialX = e.clientX - xOffset;
		  }

		  if (e.target === item) {
		    active = true;
		  }
		};

		const dragEnd = function() {
		  initialX = currentX;

		  active = false;
		};

		const checkDir = function(offset) {
			if (offset >= 0) {
				$swipeLeft.removeClass('is-visible');
			} else if (offset <= containerWidth - itemWidth) {
				$swipeRight.removeClass('is-visible');
			} else {
				$swipeRight.addClass('is-visible');
				$swipeLeft.addClass('is-visible');
			}
		};

		const setTranslate = function(xPos, el) {
		 	el.style.transform = 'translate3d(' + xPos + 'px, ' + '0, 0)';
		 	checkDir(xPos);
		};

		const drag = function(e) {
		  if (active) {
		  
		    e.preventDefault();

		    if (scrollFlag) {
		    	scrollFlag = !scrollFlag;
		    	// $swipeRight.removeClass('is-visible');
		    	// $swipeLeft.removeClass('is-visible');
		    	// setTimeout(() => {
		    	// 	$swipeRight.removeClass('is-untouched');
		    	// 	$swipeLeft.removeClass('is-untouched');
		    	// }, 300);
		    }
		  
		    if (e.type === 'touchmove') {
		      currentX = e.touches[0].clientX - initialX;
		    } else {
		      currentX = e.clientX - initialX;
		    }

		    // LEFT BORDER
		    if (currentX < (containerWidth - itemWidth)) {
		    	currentX = containerWidth - itemWidth;
		    }

		    // RIGHT BORDER
		    if (currentX > 0) {
		    	currentX = 0;
		    }

		    if (currentX > -100) {
		    	$swipeRight.removeClass('is-active');
		    	$swipeLeft.addClass('is-active');
		    } else if (currentX - 100 < (containerWidth - itemWidth)) {
		    	$swipeRight.addClass('is-active');
		    	$swipeLeft.removeClass('is-active');
		    } else {
		    	$swipeRight.removeClass('is-active');
		    	$swipeLeft.removeClass('is-active');
		    }

		    xOffset = currentX;

		    setTranslate(currentX, item);
		  }
		};

		// CLICK
		$swipeLeft.on('click', function() {
			xOffset = xOffset + 400;
			console.log(xOffset);
			if (xOffset > 0) {
				xOffset = 0;
			}
			checkDir(xOffset);
			$item.addClass('banners__panorama--animating');
			item.style.transform = 'translate3d(' + (xOffset) + 'px, ' + '0, 0)';
			setTimeout(() => {
				$item.removeClass('banners__panorama--animating');
			}, 300);
		});
		$swipeRight.on('click', function() {
			xOffset = xOffset - 400;
			console.log(xOffset);
			console.log('clicked right');
			if (xOffset <= containerWidth - itemWidth) {
				xOffset = containerWidth - itemWidth;
			}
			checkDir(xOffset);
			$item.addClass('banners__panorama--animating');
			item.style.transform = 'translate3d(' + (xOffset) + 'px, ' + '0, 0)';
			setTimeout(() => {
				$item.removeClass('banners__panorama--animating');
			}, 300);
		});

		container.addEventListener('touchstart', dragStart, false);
		container.addEventListener('touchend', dragEnd, false);
		container.addEventListener('touchmove', drag, false);

		container.addEventListener('mousedown', dragStart, false);
		container.addEventListener('mouseup', dragEnd, false);
		container.addEventListener('mousemove', drag, false);

		$(window).on('resize', () => {
			calculateWidths();
		});

	}
}