export function init() {
	'use strict';

	const $repairs = $('.js-repair');

	if ($repairs.length) {

		const $body = $('.js-site-body');

		$body.trigger('click');

		$repairs.each(function() {
			const $repair = $(this);
			const $vid = $repair.find('.js-repair-vid');
			const vid = $vid.find('video')[0];

			if ($(window).outerWidth() > 1200) {
				$repair.on('mouseenter', () => {
					vid.play();
				});
			} else {
				$repair.on('click', () => {
					if ($vid.hasClass('mobile-ready')) {
						$vid.removeClass('mobile-ready');
						vid.stop();
					} else {
						$vid.addClass('mobile-ready');
						vid.play();
					}
				});
			}

			vid.addEventListener('ended', () => {
				$vid.removeClass('mobile-ready');
			});

		});

	}

}